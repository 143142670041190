import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Video from '../../assets/eminem.mp4';
import PlayIcon from '../../assets/play.svg';
import VolumeIcon from '../../assets/sound-active.svg';
import VolumeDisable from '../../assets/sound-disable.svg';
import CloseIcon from '../../assets/close.svg';

// import { t } from '../../i18n';
import styles from './styles.module.scss';

const ExamplePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [volume, setVolume] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  // const [isPlay, setIsPlay] = useState(false);

  const openVideo = () => {
    if (isOpen) return;
    setIsOpen(true);
    setVolume(1);
  };

  const closeVideo = () => {
    setIsOpen(false);
    setVolume(0);
    setIsPlaying(true);
  };

  const muteVideo = () => {
    setVolume(volume === 1 ? 0 : 1);
  };

  const pause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div
      onClick={openVideo}
      className={isOpen ? styles.opennedContainer : styles.container}
    >
      <ReactPlayer
        className={styles['react-player']}
        width={isOpen ? 337 : 100}
        height={isOpen ? 663 : 200}
        url={Video}
        volume={volume}
        playing={isPlaying}
        muted={!isOpen}
        loop
      />
      {isOpen ? (
        <div
          className={styles.info}
          onClick={(e) => {
            if (e.target.alt || e.target.type) return;
            pause();
          }}
        >
          <div className={styles['info-container']}>
            <div className={styles.description}>
              <h2 className={styles.title}>This is FacePop!</h2>
              <p className={styles['description-text']}>
                A widget you can use to upload videos and get personal with your
                customers to schedule meetings, ask for reviews, or share the
                latest features with its CTA functionality.
              </p>
            </div>
            <div className={styles.controls}>
              <button
                className={styles['control-buttons']}
                type="button"
                onClick={closeVideo}
              >
                <img
                  className={styles['controls-volume']}
                  src={CloseIcon}
                  alt="CloseIcon"
                />
              </button>
              <div className={styles['controls-wrap']}>
                <button
                  className={styles['control-buttons']}
                  style={{ marginBottom: '20px' }}
                  type="button"
                  onClick={muteVideo}
                >
                  <img
                    className={styles['controls-volume']}
                    src={volume === 1 ? VolumeDisable : VolumeIcon}
                    alt="volumeIcon"
                  />
                </button>
                <button
                  className={styles['control-buttons']}
                  type="button"
                  onClick={pause}
                >
                  <img
                    className={styles['controls-volume']}
                    src={PlayIcon}
                    alt="playIcon"
                  />
                </button>
              </div>
            </div>
          </div>
          <button className={styles['description-btn']} type="button">
            Press me
          </button>
        </div>
      ) : (
        <button className={styles['play-button']} type="button">
          <img
            className={styles['play-button-icon']}
            src={PlayIcon}
            alt="123"
          />
        </button>
      )}
    </div>
  );
  // return <div className={styles.popupContainer}>123</div>;
};

export default ExamplePage;

// RESOURCE USAGE EXAMPLE:
// import { connect } from 'react-redux';
//
// import { actions as userActions } from '../../resources/users';
//
// const ExamplePage = ({ users, fetchUsers }) => {
//   useEffect(fetchUsers, []);
//
//   return <div>{isFetching ? <Spinner /> : users.map(({ name }) => name)}</div>;
// };
// export default connect(
//   (state) => ({
//     users: state.users.items,
//     isFetching: state.users.isFetching,
//     pageIndex: state.users.pageIndex,
//     totalItemsCount: state.users.totalItemsCount,
//   }),
//   {
//     fetchUsers: userActions.fetchUsers,
//   }
// )(ExamplePage);
